import { Component, OnInit, Renderer2, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HomeService } from '../home/home.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit, OnDestroy {

  contactObj: any = {};

  isLoading: boolean = false;
  year: any = new Date().getFullYear();
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private homeService: HomeService,
    private toastr: ToastrService
  ) { }
  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'artistBody');
  }
  ngOnInit() {
    this.renderer.addClass(this.document.body, 'artistBody');

    $("input").focus(function () {
      $(this)
        .parents(".input-group")
        .addClass("focused");
    });
    $("textarea").focus(function () {
      $(this)
        .parents(".input-group")
        .addClass("focused");
    });

    $("input").blur(function () {
      var inputValue = $(this).val();
      if (inputValue == "") {
        $(this).removeClass("filled");
        $(this)
          .parents(".input-group")
          .removeClass("focused");
      } else {
        $(this).addClass("filled");
      }
    });
    $("textarea").blur(function () {
      var inputValue = $(this).val();
      if (inputValue == "") {
        $(this).removeClass("filled");
        $(this)
          .parents(".input-group")
          .removeClass("focused");
      } else {
        $(this).addClass("filled");
      }
    });
  }
  onSubmitcontact(frm: any) {
    this.isLoading = true;
    if (this.contactObj.phone.length == 10) {
      let contactReqObj = {
        "fullName": this.contactObj.firstName,
        "email": this.contactObj.email,
        "message": this.contactObj.message
      }
      this.homeService.submitContact(contactReqObj).subscribe((data) => {
        if (data.success == true) {
          this.toastr.success(data.message, "", { timeOut: 2000 });
          $(".input-group").removeClass("focused");
          frm.resetForm();
          this.isLoading = false;
        } else {
          this.isLoading = false;
          this.toastr.error(data.message, "", { timeOut: 2000 });
        }
      }, (eror) => {
        this.isLoading = false;
      })
    } else {
      this.isLoading = false;
      this.toastr.error("Please enter minimum 10 number", '', { timeOut: 2000 });
    }
  }

}
