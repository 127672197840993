import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class EventEmiterService {

  dataStr = new EventEmitter();
  private emitChangeSource = new Subject();
  changeEmitted$ = this.emitChangeSource.asObservable();
  private purchaseSource = new Subject();
  ticketPurchased$ = this.purchaseSource.asObservable();


  constructor() { }

  sendMessage(data: string) {
    this.dataStr.emit(data);
  }
  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }
  ticketPurchased(change: any) {
    this.purchaseSource.next(change);
  }

}