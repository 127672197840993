import { CommonService } from 'src/app/shared/service';
import { Component, OnInit, Renderer2, OnDestroy, Inject, Input, ChangeDetectionStrategy } from '@angular/core';
import { HomeService } from "../home/home.service";
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, OnDestroy {

  searchData: any = [];
  searchModel: any = '';
  noResultFound: boolean = false;
  constructor(private spinner: NgxSpinnerService,
              public commonService: CommonService,
              private router: Router,
              private route: ActivatedRoute,
              private homeService: HomeService,
              @Inject(DOCUMENT) private document: Document,
              private renderer: Renderer2, ) {
    this.searchModel = localStorage.getItem('searchtext');
    this.onSearchChange();
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'artistBody');
  }

  ngOnInit() {
    let userData = this.commonService.getLocalStorage("userDetails");
    if(userData == undefined || userData == "" || userData == null) {
      this.spinner.hide();
      this.router.navigate(['home']);
    }
    this.renderer.addClass(this.document.body, 'artistBody');
    this.searchModel = localStorage.getItem('searchtext');
    this.onSearchChange();
  }
  onSearchChange(): void {
    this.spinner.show();
    if (this.searchModel != null || this.searchModel != undefined) {
      if (this.searchModel.length > 2 && this.searchModel != null && this.searchModel != undefined) {
        let searchObj = {
          keyword: this.searchModel,
          "contentType": ["cast", "asset"]
        };
        this.homeService.search(searchObj).subscribe(data => {
          if (data.success == true) {
            this.spinner.hide();
            localStorage.removeItem('searchtext');
            this.searchData = data.data.data;
            console.log("this.searchData", this.searchData);
            if (this.searchData == undefined || this.searchData.length <= 0) {
              this.noResultFound = true;
            } else {
              this.noResultFound = false;
              return this.searchData;
            }
          } else {
            localStorage.removeItem('searchtext');
            this.spinner.hide();
          }
        });
      }
    } else {
      this.spinner.hide();
    }
  }

}
