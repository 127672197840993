import { Component, OnInit } from "@angular/core";
import { HomeService } from "src/app/home/home.service";
import { Router } from '@angular/router';

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})
export class FooterComponent implements OnInit {
  footerLink: any = [];
  year: any = new Date().getFullYear();
  constructor(private homeService: HomeService, private router: Router) { }

  ngOnInit() {
    //get Footer Menu
    this.homeService.getFooter().subscribe(data => {
      if (data.success == true) {
        this.footerLink = data.data.data;
      }
    });
  }

  // goToFooterLinkData(footerlink: string, path: string): void {
  //   console.log(footerlink);
  //   if (footerlink === 'About Us') {
  //     window.open(window.location.origin + '/about-us/' + path, '_blank');
  //     //window.open(window.location.origin + '/about-us/' + path);
  //   } else if (footerlink === 'Terms Of Use') {
  //     window.open(window.location.origin + '/terms-of-use/' + path, '_blank');
  //     //window.open(window.location.origin + '/terms-of-use/' + path);
  //   } else if (footerlink === 'Privacy Policy') {
  //     window.open(window.location.origin + '/privacy-policy/' + path, '_blank');
  //     //window.open(window.location.origin + '/privacy-policy/' + path);
  //   }
  // }
}
