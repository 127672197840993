import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Subject, BehaviorSubject } from 'rxjs';
import { idLocale } from 'ngx-bootstrap';
import { start } from 'repl';

@Injectable()
export class CommonService {
	timerObj: any = {};
	// private emitChangeSource = new BehaviorSubject<any>('');
	// changeEmitted$ = this.emitChangeSource.asObservable();

	// - loginSource
	// 1 = google / 2 = facebook / 3 = registrationform


	// Device Type
	// 2 = Web
	// 3 = Android
	// 4 = iOS
	// 5 = Roku
	constructor() {

	}
	setLocalStorage(key, storeObj) {

		localStorage.setItem(key, JSON.stringify(storeObj))
	}
	getLocalStorage(key) {
		let itemValue = localStorage.getItem(key);
		if (itemValue != "" && itemValue != null) {
			let decrypted = JSON.parse(itemValue);
			return decrypted;
		} else {
			return '';
		}

	}
	calculateDays(date, timeZone,index) {
		//if (<any>new Date(date) >= <any>new Date()) {
		//console.log(date + timeZone);
		if (timeZone == null) {
			timeZone = "-5:00";
		}
		let dateStr = moment(date).utc().format('YYYY-MM-DD HH:mm:ss') + timeZone
		let offset = timeZone;

		let [h, m] = offset.split(':');

		let hours1 = Number.parseInt(h);
		let minutes1 = Number.parseInt(m);

		let totalMinutes = hours1 * 60 + (hours1 < 0 ? (-minutes1) : minutes1);
		//console.log(totalMinutes);
		let a = moment(moment(date).format('YYYY-MM-DD HH:mm:ss')).subtract(totalMinutes, 'minutes').toDate();
	
		//console.log(new Date());
		//console.log(moment().utcOffset(this.showDetails.airStartTimezone));
		//console.log(moment(this.showDetails.airStartDate).utc().format('YYYY-MM-DDTHH:mm:ss'));
		//console.log(moment().utcOffset(moment(this.showDetails.airStartDate).utc().format('YYYY-MM-DDTHH:mm:ss').toString()+this.showDetails.airStartTimezone).format("YYYY-MM-DD HH:mm:ss"));
		//console.log(moment.parseZone(moment(this.showDetails.airStartDate).utc().format('YYYY-MM-DDTHH:mm:ss')+this.showDetails.airStartTimezone).local().format());

		let d = <any>a - <any>new Date();

		let days = Math.floor(d / 1000 / 60 / 60 / 24);
		let hours = Math.floor(d / 1000 / 60 / 60 - days * 24);
		let minutes = Math.floor(
			d / 1000 / 60 - days * 24 * 60 - hours * 60
		);
		let seconds = Math.floor(
			d / 1000 - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60
		);
		let milliseconds = Math.floor(
			d -
			days * 24 * 60 * 60 * 1000 -
			hours * 60 * 60 * 1000 -
			minutes * 60 * 1000 -
			seconds * 1000
		);
		let t = {};
		["days"].forEach(
			q => {
				if (eval(q) >= 0) {
					t[q] = eval(q);
				}
			}
		);
		let th = {};
		["days", "hours", "minutes"].forEach(
		  d => {
			if (eval(d) >= 0) {
				th[d] = eval(d);
			}
		  }
		);
t['index']=index
th['index']=index
		if (t['days'] > 0) {
			this.timerObj = t;
		
			return this.timerObj;
		} else {
			this.timerObj = th;
			return this.timerObj
		}
		//return this.timerObj.days;
		// } else {
		// 	return 0;
		// }

	}
	checkIsLive(startDate, endDate, timeZone) {
		if (timeZone == null) {
			timeZone = "-5:00";
		}
		let offset = timeZone;

		let [h, m] = offset.split(':');

		let hours1 = Number.parseInt(h);
		let minutes1 = Number.parseInt(m);

		let totalMinutes = hours1 * 60 + (hours1 < 0 ? (-minutes1) : minutes1);
		//console.log(new Date(startDate), new Date(endDate))
		let localStartDate = moment(moment.utc(startDate).local().format('YYYY-MM-DD HH:mm:ss')).subtract(totalMinutes, 'minutes').toDate();
		let localEndDate = moment(moment.utc(endDate).local().format('YYYY-MM-DD HH:mm:ss')).subtract(totalMinutes, 'minutes').toDate();
		//console.log(localStartDate,localEndDate);
		if (moment().isBetween(localStartDate, localEndDate)) {
			return true;
		} else {
			return false;
		}
	}
	checkIsWarmingUp(startDate, timeZone) {
		if (timeZone == null) {
			timeZone = "-5:00";
		}
		let offset = timeZone;

		let [h, m] = offset.split(':');

		let hours1 = Number.parseInt(h);
		let minutes1 = Number.parseInt(m);

		let totalMinutes = hours1 * 60 + (hours1 < 0 ? (-minutes1) : minutes1);
		//console.log(totalMinutes);
		let a = moment(moment(startDate).format('YYYY-MM-DD HH:mm:ss')).subtract(totalMinutes, 'minutes').toDate();
		if (moment().isBefore(a)) {
			return true;
		} else {
			return false;
		}
	}
	checkIsPlayed(endDate, timeZone) {
		if (timeZone == null) {
			timeZone = "-5:00";
		}
		let offset = timeZone;

		let [h, m] = offset.split(':');

		let hours1 = Number.parseInt(h);
		let minutes1 = Number.parseInt(m);

		let totalMinutes = hours1 * 60 + (hours1 < 0 ? (-minutes1) : minutes1);
		//console.log(new Date(startDate), new Date(endDate))
		let localEndDate = moment(moment.utc(endDate).local().format('YYYY-MM-DD HH:mm:ss')).subtract(totalMinutes, 'minutes').toDate();
		//console.log(localStartDate,localEndDate);
		if (moment().isAfter(localEndDate)) {
			return true;
		} else {
			return false;
		}
	}
	checkIsDateValid(startDate, endDate, timeZone) {
		if (timeZone == null) {
			timeZone = "-5:00";
		}
		let offset = timeZone;

		let [h, m] = offset.split(':');

		let hours1 = Number.parseInt(h);
		let minutes1 = Number.parseInt(m);

		let totalMinutes = hours1 * 60 + (hours1 < 0 ? (-minutes1) : minutes1);
		//console.log(new Date(startDate), new Date(endDate))
		let localStartDate = moment(moment.utc(startDate).local().format('YYYY-MM-DD HH:mm:ss')).subtract(totalMinutes, 'minutes').toDate();
		let localEndDate = moment(moment.utc(endDate).local().format('YYYY-MM-DD HH:mm:ss')).subtract(totalMinutes, 'minutes').toDate();
		if ((<any>localStartDate > <any>localEndDate) || (<any>localStartDate < <any>localEndDate)) {
			return true;
		} else {
			return false;
		}
  }

  getStartDate(startDate, timeZone) {
    if (timeZone == null) {
			timeZone = "-5:00";
		}
		var offset = timeZone;

		var [h, m] = offset.split(':');

		var hours1 = Number.parseInt(h);
		var minutes1 = Number.parseInt(m);

		var totalMinutes = hours1 * 60 + (hours1 < 0 ? (-minutes1) : minutes1);
		//console.log(new Date(startDate), new Date(endDate))
    var localStartDate = moment(moment.utc(startDate).local().format('YYYY-MM-DD HH:mm:ss')).subtract(totalMinutes, 'minutes').toDate();
    // console.log(localStartDate);
    // console.log(moment().utc().isDST(), "console.log(moment().utc().isDST());");
    return localStartDate;
  }
}
