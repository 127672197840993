import { Component, OnInit } from "@angular/core";
import { RouterModule, Routes, ActivatedRoute, Router } from "@angular/router";
import { HomeService } from "../home/home.service";

@Component({
  selector: "app-footer-data",
  templateUrl: "./footer-data.component.html",
  styleUrls: ["./footer-data.component.css"]
})
export class FooterDataComponent implements OnInit {
  linkId: any = 0;
  pageData: any = {};
  constructor(private route: ActivatedRoute, private homeService: HomeService) {
    this.route.params.subscribe(params => {
      if (params.path !== undefined) {
        this.linkId = params.path;
        this.getPageData();
      }
    });
  }

  ngOnInit() {}
  getPageData() {
    this.homeService.getFooterData(this.linkId).subscribe(data => {
      console.log(data);
      if (data.success == true) {
        this.pageData = data.data[0];
      }
    });
  }
}
