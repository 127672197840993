import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json"    
  })
};

@Injectable({
  providedIn: "root"
})
export class ShowService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getShowDetail(assetId: any, userId): Observable<any> {
    let filter = encodeURI(
      JSON.stringify({"userId": userId})
    );
    return this.http.get(this.apiUrl + "/v1/asset/" + assetId +"?filter=" + filter);
  }
  getShowingThisWeek(): Observable<any> {
    let filter = encodeURI(
      JSON.stringify({ sortBy: "showingThisWeek" }) + "&start=0&limit=3"
    );
    return this.http.get(this.apiUrl + "/v1/asset?filter=" + filter);
  }
  watchlive(showId: any, userId): Observable<any> {
    let filter = encodeURI(
      JSON.stringify({"userId": userId})
    );
    return this.http.get(this.apiUrl + "/v1/asset/" + showId + "?filter=" + filter);
  }
  getUserProfile(userId: any): Observable<any> {
    return this.http.get(this.apiUrl + '/v1/profile/' + userId);
  }
  userReaction(userReactions: any): Observable<any> {
    return this.http.post(this.apiUrl + '/v1/reactions', userReactions);
  }
  addToWatchlist(watchListObj: any): Observable<any> {
    return this.http.post(this.apiUrl + '/v1/watchlist', watchListObj);
  }
  getWatchList(watchListObj: any): Observable<any> {
    let filter = '';
    if (Object.keys(watchListObj).length > 0) {
      filter = "?filter=" + encodeURI(JSON.stringify(watchListObj));
    }
    return this.http.get(this.apiUrl + '/v1/watchlist' + filter);
  }
  getAllShow(filterObj: any = {}): Observable<any> {
    let filter = encodeURI(
      JSON.stringify(filterObj)
    );
    return this.http.get(this.apiUrl + "/v1/asset?filter=" + filter);
  }
  addToBookmark(bookmarkObj: any): Observable<any> {
    return this.http.post(this.apiUrl + '/v1/bookmark', bookmarkObj);
  }
  getShows(filterObj: any, limit): Observable<any> {
    let filter = encodeURI(
      JSON.stringify(filterObj)
    );
    return this.http.get(this.apiUrl + "/v1/asset?filter=" + filter + '&start=0&limit=' + limit);
  }
  getPreplayDetails(filterObj: any): Observable<any> {
    let filter = (
      (filterObj)
    );
    return this.http.get(this.apiUrl + "/v1/asset/drm" + filter);
  }
  getPrePlayAPI(filterObj: any): Observable<any> {    
    return this.http.get(filterObj);
  } 
}
