import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FooterDataComponent } from './footer-data/footer-data.component';
import { FaqComponent } from './faq/faq.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SearchComponent } from './search/search.component';


const routes: Routes = [

  { path: 'home', loadChildren: './home/home.module#HomeModule' },
  { path: 'register', loadChildren: './register/register.module#RegisterModule' },
  { path: 'forgotpassword', loadChildren: './forgot-password/forgot-password.module#ForgotPasswordModule' },
  { path: 'shows', loadChildren: './show/show.module#ShowModule' },
  { path: 'analytics', loadChildren: './analytics-portal/analytics-portal.module#AnalyticsPortalModule' },
  { path: 'artists', loadChildren: './artist/artist.module#ArtistModule' },
  { path: 'buyticket', loadChildren: './buy-ticket/buy-ticket.module#BuyTicketModule' },
  { path: 'profile', loadChildren: './profile/profile.module#ProfileModule' },
  { path: 'search', component: SearchComponent },
  { path: ':title/:path', component: FooterDataComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'contactus', component: ContactUsComponent },
  { path: '', redirectTo: 'profile', pathMatch: 'full' },
  { path: '**', redirectTo: 'profile', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: "top",
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
