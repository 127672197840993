import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};
@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  public apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  register(registerObj:any):Observable<any>{
    return this.http.post(this.apiUrl+'/v1/register',registerObj);
  }
  sendOtp(otpObj:any):Observable<any>{
    return this.http.post(this.apiUrl+'/v1/sentotp',otpObj);
  }
  validateOtp(otpObj:any):Observable<any>{
    return this.http.post(this.apiUrl+'/v1/verifyotp',otpObj);
  }
  login(loginObj:any):Observable<any>{
    return this.http.post(this.apiUrl+'/v1/login',loginObj);
  }
  checkuser(userObj:any):Observable<any>{
    return this.http.post(this.apiUrl+'/v1/checkuser',userObj);
  }
  resendOtp(userObj:any):Observable<any>{
    return this.http.post(this.apiUrl+'/v1/sendotp',userObj);
  }
  changePassword(passwordObj: any): Observable<any> {
    return this.http.post(this.apiUrl + '/v1/changepassword', passwordObj);
  }
  getGeo(): Observable<any>{
    return this.http.get(this.apiUrl + '/geo');
  }
}
