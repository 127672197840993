import { ToastrService } from 'ngx-toastr';
import { RegisterService } from './../../register/register.service';
import { CommonService } from './../../shared/service/index';
import { BsModalRef } from 'ngx-bootstrap';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
  providers: [CommonService]
})
export class ChangePasswordComponent implements OnInit {
  chanegPasswordObj: any = {};
  isLoggedIn = true;
  otpObj: any = {};
  modalRef: BsModalRef;
  resendOTP = false;
  hideModal = true;
  isLoading = false;
  resendOtpLoader = false;

  userDetails: any = {};

  constructor(public commonService: CommonService,
              public registerService: RegisterService,
              public toastr: ToastrService,
              public bsModalRef: BsModalRef) { }

  ngOnInit() {
    $('input').focus(function() {
      $(this)
        .parents('.input-group')
        .addClass('focused');
    });

    $('input').blur(function() {
      const inputValue = $(this).val();
      if (inputValue == '') {
        $(this).removeClass('filled');
        $(this)
          .parents('.input-group')
          .removeClass('focused');
      } else {
        $(this).addClass('filled');
      }
    });
    this.isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
    const userData = this.commonService.getLocalStorage('userDetails');
    if (this.isLoggedIn == true && userData != undefined) {
      this.userDetails = userData;
    }
  }

  onSubmit(changepasswordFrm) {
    this.isLoading = true;
    const changePasswordReq = {
      emailId: this.userDetails.emailId,
      password: changepasswordFrm.form.value.password,
      newPassword: changepasswordFrm.form.value.newpassword
    };
    this.changePassword(changePasswordReq);
  }

  changePassword(changePasswordReq) {
    if (changePasswordReq.currentPassword != changePasswordReq.newPassword) {
    this.registerService.changePassword(changePasswordReq).subscribe(data => {
      if (data.success == true) {
        this.resetForm();
        this.closeModel();
        this.isLoading = false;
        this.bsModalRef.hide();
        this.toastr.success(data.message, '', { timeOut: 1000 });
      } else {
        this.isLoading = false;
        this.toastr.error(data.message, '', { timeOut: 1000 });
        // this.closeModel();
      }
    });
    } else {
      this.isLoading = false;
      this.toastr.error('CurrentPassword and New password both are same', '', { timeOut: 1000 });
    }
  }

  closeModel() {
    this.bsModalRef.hide();
  }

  resetForm() {
    this.chanegPasswordObj = {};
  }

}
