import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { ModalModule } from "ngx-bootstrap";
import { ToastrModule } from "ngx-toastr";
import {
  AuthServiceConfig,
  FacebookLoginProvider,
  GoogleLoginProvider,
  LoginOpt,
  AuthService
} from "angularx-social-login";
import { environment } from "src/environments/environment";
import { LoginComponent } from './components/login/login.component';
import { OtpComponent } from './components/otp/otp.component';
import { EventEmiterService } from './shared/service/event.emmiter.service';
import { LaddaModule } from 'angular2-ladda';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxSpinnerModule } from "ngx-spinner";
import { FooterDataComponent } from './footer-data/footer-data.component';
import { FaqComponent } from './faq/faq.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ActiveComponentGuard } from './Guard/active-component.guard';
import { SearchComponent } from './search/search.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CommonService } from './shared/service';
const googleLoginOptions: LoginOpt = {
  prompt: "select_account"
};
const config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.facebookAuthId)
  },
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(
      environment.googleAuthKey,
      googleLoginOptions
    )
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent, LoginComponent, OtpComponent, FooterDataComponent,
    FaqComponent, ContactUsComponent, SearchComponent, ChangePasswordComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    LaddaModule.forRoot({
      style: "zoom-out",
      spinnerSize: 40,
      spinnerColor: "white",
      spinnerLines: 12
    }),
    NgxPageScrollModule,
    NgxSpinnerModule
  ],
  providers: [
    ActiveComponentGuard,
    HeaderComponent,
    CommonService,
    AuthService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    EventEmiterService
  ],
  bootstrap: [AppComponent],
  exports: [HeaderComponent],
  entryComponents: [LoginComponent, OtpComponent, ChangePasswordComponent]
})
export class AppModule { }
