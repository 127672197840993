import {
  Component,
  OnInit,
  TemplateRef,
  ElementRef,
  AfterViewInit,
  Renderer2,
  ViewChild
} from "@angular/core";
import {
  BsModalService,
  BsModalRef,
  ModalDirective
} from "ngx-bootstrap/modal";
import { RegisterService } from "../../register/register.service";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "../../shared/service";
import { HomeService } from "../../home/home.service";
import { SocialUser, AuthService } from "angularx-social-login";
import { LoginComponent } from "../login/login.component";
import { ShowService } from "../../show/show.service";
import { EventEmiterService } from "../../shared/service/event.emmiter.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AngularWaitBarrier } from "blocking-proxy/built/lib/angular_wait_barrier";
import * as _ from 'underscore';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  providers: [CommonService, ShowService]
})
export class HeaderComponent implements OnInit, AfterViewInit {
  modalRef: BsModalRef;
  loginModel: any;
  loginObj: any = {};
  navigationMenu: any = [];
  otpObj: any = {};
  user: SocialUser;
  isLoggedIn = false;
  userDetails: any = {};
  ticketDetails: any = 0;
  showLoginBtn: boolean = true;
  modalRefLogout: BsModalRef;
  searchData: any = [];
  searchTxt: any = ''
  searchTextNewValue: any = '';
  @ViewChild("childModal", { static: false }) childModal: ModalDirective;
  homePage = false;

  //AnimotionsZ Begin
  roleId: number = null;
  //AnimotionsZ End

  constructor(
    private homeService: HomeService,
    private modalService: BsModalService,
    private registerService: RegisterService,
    private toastr: ToastrService,
    public commonService: CommonService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private eventEmmiterService: EventEmiterService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {

  }
  ngOnInit() {
    // const navId = document.getElementById("navClass");
    // if (this.router.url.indexOf("artist/detail")) {
    //   this.renderer.addClass(navId, "active");
    // }
    this.homeService.getNavigation().subscribe(data => {
      if (data.success == true) {
        this.navigationMenu = data.data.data;
        localStorage.setItem('navigationlength', this.navigationMenu.length);
      }
    });
    this.router.events.subscribe((val: any) => {
      if (val.url != undefined) {
        if (val.url == "/register") {
          this.showLoginBtn = false;
        } else {
          this.showLoginBtn = true;
        }
        if (val.url != '/home') {
          this.homePage = false;
        }
        let validUrl = ["", "/", "/show", "/artist"];
        if (val.url == '/home') {
          let myTag = this.el.nativeElement.querySelectorAll(
            ".list-menu ul li a"
          );
          myTag.forEach(element => {
            if (!element.classList.contains("active")) {
              this.homePage = true
            }
          });

        } else if (!validUrl.includes(val.url)) {
          let myTag = this.el.nativeElement.querySelectorAll(
            ".list-menu ul li a"
          );
          myTag.forEach(element => {
            if (element.classList.contains("active")) {
              element.classList.remove("active");
            }
          });
        } else if (val.url == '/artist') {
          let myTag = this.el.nativeElement.querySelectorAll(
            ".list-menu ul li a.homeLink"
          );
          myTag.forEach(element => {
            if (element.classList.contains("active")) {
              element.classList.remove("active");
            }
          });
        }
      }
    });
    this.eventEmmiterService.ticketPurchased$.subscribe(data => {
      this.getUserProfile();
    });

    this.eventEmmiterService.changeEmitted$.subscribe(data => {
      if (data == true) {
        this.isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));
        let userData = this.commonService.getLocalStorage("userDetails");
        if (this.isLoggedIn == true && userData != undefined) {
          this.userDetails = userData;
          this.getUserProfile();
        }
        this.loadJquery();
      } else {
        this.isLoggedIn = false;
      }
    });
    this.isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));
    let userData = this.commonService.getLocalStorage("userDetails");
    if (this.isLoggedIn == true && userData != undefined) {
      this.userDetails = userData;
      this.getUserProfile();
    }
  }
  ngAfterViewInit() {
    this.loadJquery();
  }
  loadJquery() {
    $(document).ready(function () {
      (<any>$("#sidebar")).mCustomScrollbar({
        theme: "minimal"
      });

      $("#dismiss, .overlay, .signoutBtn, .closesideBar, .searchListItem").on(
        "click",
        function () {
          $("#sidebar").removeClass("active");
          $(".overlay").removeClass("active");
        }
      );

      $(".sidebarCollapse").on("click", function () {
        $("#sidebar").addClass("active");
        $(".overlay").addClass("active");
        $(".collapse.in").toggleClass("in");
        $("a[aria-expanded=true]").attr("aria-expanded", "false");
        $("#Modal").hide();
      });

      (<any>$(".box-container-1")).tooltipster({
        interactive: true,
        maxWidth: 267
      });
      $(".tablist ul li a").click(function () {
        $(".tablist ul li").removeClass("active");
        // $(this)
        //   .parent("li")
        //   .addClass("active");

        var active_div = $(this).attr("dtat-target");
        $(".tabing").hide();
        $("#" + active_div).show();
      });
      $(".tgBUtton").click(function () {
        var own_hid = $(this).attr("data-hidden");
        var own_open = $(this).attr("data-attr");
        var other_hid = $(this).attr("data-hide");
        var other_open = $(this).attr("data-open");

        $("." + own_hid).hide();
        $("#" + own_open).show();

        $("#" + other_hid).hide();
        $("." + other_open).css("display", "flex");
      });
    });
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(LoginComponent, {
      class: "modal-dialog-centered new-login-class"
    });
  }

  closeModal() {
    this.modalRef.hide();
  }
  getUserProfile() {
    const userId = this.userDetails.id;
    this.homeService.getUserProfile(userId).subscribe(data => {
      if (data.success == true) {
        this.userDetails.ticketsAvailable = data.data[0].ticketsAvailable;
        //AnimotionsZ Begin
        console.log(">>roleId", data.data[0].roleId)
        this.roleId = data.data[0].roleId;
        //AnimotionsZ End
      }
    });
  }
  onSearchChange(): void {
    this.searchTextNewValue = this.searchTxt;
    if (this.searchTextNewValue.length > 2) {
      let searchObj = {
        keyword: this.searchTextNewValue,
        contentType: ["cast", "asset"]
      };
      this.homeService.sidebarSearch(searchObj).subscribe(data => {
        if (data.success == true) {
          if(data.data.data.length > 0) {
            this.childModal.show();
          } else {
            $('.search-modal').css('display: none !important');
            this.childModal.hide();
          }
          this.searchData = data.data.data;
        }
      });
    } else {
      this.searchData = [];
      $('.search-modal').css('display: none !important');
      this.childModal.hide();
    }
  }
  goToShow(showId) {
    this.childModal.hide();
    this.router.navigate(["/shows", showId]);
  }
  signOut(content) {
    this.modalRefLogout = this.modalService.show(content, {
      class: "logoutConfirmModal"
    });
  }
  confirmLogout() {
    this.authService.signOut();
    this.commonService.setLocalStorage("isLoggedIn", false);
    this.commonService.setLocalStorage("emailsubscribed", true);
    localStorage.removeItem("navigation");
    localStorage.removeItem("userDetails");
    this.readLocalStorageValue();
    this.eventEmmiterService.emitChange(
      this.commonService.getLocalStorage("isLoggedIn")
    );
    this.eventEmmiterService.changeEmitted$.subscribe(
      data => {
        if (data == true) {
          //this.ngOnInit();
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
        }
      },
      error => {
        console.log("eeeeeeeeeee", error);
      }
    );
    this.modalRefLogout.hide();
    this.toastr.success("Logout successfully", "", { timeOut: 2000 });
    this.router.navigate(["home"]);
  }
  readLocalStorageValue() {
    this.isLoggedIn = JSON.parse(
      this.commonService.getLocalStorage("isLoggedIn")
    );
    return this.isLoggedIn;
  }
  highlightItem(event) {
    this.closeChildModal()
    console.log(event);
    let myTag = this.el.nativeElement.querySelectorAll(".list-menu ul li a");
    myTag.forEach(element => {
      if (element.classList.contains("active")) {
        element.classList.remove("active");
      }
    });
    setTimeout(() => {
      if (!event.target.classList.contains("active")) {
        event.target.classList.add("active");
      }
    }, 0);
  }

  onSubmitcontact(searchTxt) {
    // console.log(searchFrm);

    this.searchTextNewValue = searchTxt;
    if (this.searchTextNewValue.length > 2) {
      $("#sidebar").removeClass("active");
      $(".overlay").removeClass("active");
      this.childModal.hide();
      localStorage.setItem('searchtext', this.searchTextNewValue);
      if(this.router.url != '/search') {
        this.router.navigate(["/search"]);
      } else {
        window.location.reload();
      }
    } else {
      this.childModal.hide();
    }
  }

  goToHome() {
    if (this.router.url == "/home") {
      window.scrollTo(0, 0);
      let myTag = this.el.nativeElement.querySelectorAll(".list-menu ul li a");
      myTag.forEach(element => {
        if (element.classList.contains("active")) {
          element.classList.remove("active");
        }
      });
      myTag[0].classList.add("active");
    } else {
      this.router.navigate(['home']);
    }
  }

  closeChildModal() {
    this.childModal.hide();
  }
}
