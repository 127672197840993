// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl:"http://localhost:3200",
  apiUrl: "https://5jt3oa4k68.execute-api.us-east-1.amazonaws.com/dev/", // dev
   //apiUrl: "https://mcapi.dcafecms.com",  // prod//
  googleAuthKey:
    "294101970311-8ld4l464gacjv9bck8ne4bf5gllbmhbd.apps.googleusercontent.com", //dev
  // googleAuthKey:
  // "347409324739-9phh9qg33k3a6tsib5b8vpqgf0p41dgc.apps.googleusercontent.com", //prod
  facebookAuthId: "947271352314247", // dev
  // facebookAuthId: "151335786256440", // prod
  stripeKey: "pk_test_9EjrHRgtqlBAKLuFA6oLRMU6" // test
  // stripeKey:"pk_live_XmUTOJeJ6gbElv8f91bwZHCg00N2QW3qeO" // live
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
