import { Router, NavigationEnd } from '@angular/router';
import { Component } from '@angular/core';
// import{EventEmiterService} from './shared/service/event.emmiter.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})
export class AppComponent {
  hideDiv = false;
  title = 'Mint Comedy';

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.hideDiv = true;
      }
    });
  }
}
