import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json"
  })
};
@Injectable({
  providedIn: "root"
})
export class HomeService {
  public apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getNavigation(): Observable<any> {
    let filter = encodeURI(JSON.stringify({ deviceTypeId: "1" }));
    return this.http.get(this.apiUrl + "/v1/navigation/?filter=" + filter);
  }
  getFooter(): Observable<any> {
    let filter = encodeURI(JSON.stringify({ deviceTypeId: "1" }));
    return this.http.get(this.apiUrl + "/v1/footerlink?filter=" + filter);
  }
  getCarousal(): Observable<any> {
    let filter = encodeURI(
      JSON.stringify({ sortBy: "upcomingShows", limit: 3 })
    );
    return this.http.get(this.apiUrl + "/v1/carousel?filter=" + filter);
  }
  getCastUpcomingShows(): Observable<any> {
    let filter = encodeURI(
      JSON.stringify({ sortBy: "upcomingShows" }) + "&start=0&limit=10"
    );
    return this.http.get(this.apiUrl + "/v1/cast?filter=" + filter);
  }
  getCast(filterObj: any = {}, limit = 10): Observable<any> {
    let filter = encodeURI(
      JSON.stringify(filterObj) + "&start=0&limit=" + limit
    );
    return this.http.get(this.apiUrl + "/v1/cast?filter=" + filter);
  }
  getShows(): Observable<any> {
    let filter = encodeURI(
      JSON.stringify({ sortBy: "upcomingShows" }) + "&start=0&limit=3"
    );
    return this.http.get(this.apiUrl + "/v1/asset?filter=" + filter);
  }
  getSubscription(): Observable<any> {
    let filter = encodeURI(
      JSON.stringify({ deviceId: "1" }) + "&start=0&limit=10"
    );
    return this.http.get(this.apiUrl + "/v1/subscription/?filter=" + filter);
  }
  getSubscriptionAssetById(id): Observable<any> {
    let filter = encodeURI(
      JSON.stringify({ deviceId: "1" , assetId: id }) + "&start=0&limit=10"
    );
    return this.http.get(this.apiUrl + "/v1/subscription/?filter=" + filter);
  }
  getUserProfile(userId): Observable<any> {
    return this.http.get(this.apiUrl + '/v1/profile/' + userId);
  }
  search(searchObj: any): Observable<any> {
    let filter = encodeURI(JSON.stringify(searchObj));
    return this.http.get(this.apiUrl + '/v1/search/?filter=' + filter);
  }
  sidebarSearch(searchObj: any): Observable<any> {
    let filter = encodeURI(JSON.stringify(searchObj) + "&start=0&limit=3");
    return this.http.get(this.apiUrl + '/v1/search/?filter=' + filter);
  }
  getFooterData(pageId): Observable<any> {
    let filter = encodeURI(
      JSON.stringify({ deviceTypeId: "1" })
    );
    return this.http.get(this.apiUrl + '/v1/footerlink/' + pageId + "?filter=" + filter);
  }
  submitContact(contactObj: any): Observable<any> {
    return this.http.post(this.apiUrl + '/v1/contact/', contactObj);
  }
  subscribe(subscribeObj: any): Observable<any> {
    return this.http.post(this.apiUrl + '/v1/newsletter', subscribeObj);
  }
  addToBookmark(bookmarkObj: any): Observable<any> {
    return this.http.post(this.apiUrl + '/v1/bookmark', bookmarkObj);
  }
  getPrePlayAPI(filterObj: any): Observable<any> {    
    return this.http.get(filterObj);
  }
}
