import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Output,
  EventEmitter
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { RegisterService } from "../../register/register.service";
import { CommonService } from "../../shared/service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import {
  SocialUser,
  AuthService,
  GoogleLoginProvider,
  FacebookLoginProvider
} from "angularx-social-login";
import { Router } from "@angular/router";
import { EventEmiterService } from "../../shared/service/event.emmiter.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  providers: [CommonService]
})
export class LoginComponent implements OnInit {
  loginObj: any = {};
  isLoggedIn = true;
  otpObj: any = {};
  modalRef: BsModalRef;
  resendOTP = false;
  hideModal = true;
  isLoading: boolean = false;
  @Output() loggedIn: EventEmitter<any> = new EventEmitter();
  @ViewChild("otpModal", { static: true }) otpModal;
  resendOtpLoader: boolean = false;
  constructor(
    public bsModalRef: BsModalRef,
    private eventEmmiterService: EventEmiterService,
    private modalService: BsModalService,
    private router: Router,
    private authService: AuthService,
    private registerService: RegisterService,
    private toastr: ToastrService,
    public commonService: CommonService
  ) { }

  ngOnInit() {
    //form
    $("input").focus(function () {
      $(this)
        .parents(".input-group")
        .addClass("focused");
    });

    $("input").blur(function () {
      var inputValue = $(this).val();
      if (inputValue == "") {
        $(this).removeClass("filled");
        $(this)
          .parents(".input-group")
          .removeClass("focused");
      } else {
        $(this).addClass("filled");
      }
    });
  }

  onSubmit(frmObj) {
    this.isLoading = true;
    const loginReq = {
      emailId: this.loginObj.email,
      password: this.loginObj.password,
      skipOTP: '1'
    };
    this.doLogin(loginReq);

  }
  doLogin(loginReq) {
    this.registerService.login(loginReq).subscribe(
      data => {
        if (data.success == true) {
          let userData = data.data[0];
          // if (userData.verified == 1) {
            this.toastr.success(data.message, '', { timeOut: 2000 });
            this.commonService.setLocalStorage("isLoggedIn", true);
            this.commonService.setLocalStorage("userDetails", userData);
            this.readLocalStorageValue();
            this.router.navigate(["profile"]);
            // window.location.reload()
            this.bsModalRef.hide();
            this.eventEmmiterService.emitChange(this.commonService.getLocalStorage('isLoggedIn'));
          // }
        } else {
          this.isLoading = false;
          this.toastr.error(data.message, '', { timeOut: 2000 });
        }
      },
      error => {
        this.isLoading = false;
        this.toastr.error(error.error.message, '', { timeOut: 2000 });
        // if (error.status == 400) {
        //   let resendOtpEmail = {
        //     emailId: this.loginObj.email
        //   }
        //   this.registerService.resendOtp(resendOtpEmail).subscribe(data => {
        //     if (data.success == true) {
        //       this.toastr.success(data.message, '', { timeOut: 2000 });
        //       this.bsModalRef.hide();
        //       this.modalRef = this.modalService.show(this.otpModal, {
        //         class: "modal-dialog-centered"
        //       });
        //       $("input").focus(function () {
        //         $(this)
        //           .parents(".input-group")
        //           .addClass("focused");
        //       });
        //       this.modalRef.hide();
        //     }
        //   })
        // } else {
        //   this.toastr.error(error.error.message, '', { timeOut: 2000 });
        // }

      }
    );
  }
  readLocalStorageValue() {
    this.isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn"));
    return this.isLoggedIn;
  }
  facebooklogin() {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(data => {
      let facebookData = data;
      const useremail = {
        emailId: facebookData.email,
        loginSource: "2",
        accessToken: facebookData.authToken
      };
      let registerReq = {
        emailId: facebookData.email,
        firstName: facebookData.firstName,
        lastName: facebookData.lastName,
        accessToken: facebookData.authToken,
        dateOfBirth: '',
        loginSource: "2",
        deviceTypeId: "1"
      };
      if (facebookData.provider === "FACEBOOK") {
        if (facebookData !== undefined) {
          //this.bsModalRef.hide();
          this.registerService.login(useremail).subscribe(
            data => {
              if (data.success == true) {
                let userData = data.data[0];
                if (userData) {
                  //   this.isLoading = false;
                  //   let resendOtpEmail = {
                  //     emailId: userData.emailId
                  //   }
                  //   this.registerService.resendOtp(resendOtpEmail).subscribe(data => {
                  //     if (data.success == true) {
                  //       this.toastr.success(data.data.msg, '', {timeOut: 2000});
                  //       this.bsModalRef.hide();
                  //       this.modalRef = this.modalService.show(this.otpModal, {
                  //         class: "modal-dialog-centered"
                  //       });
                  //       this.modalRef.hide();
                  //     }
                  //   })
                  this.toastr.success(data.message, '', { timeOut: 2000 });
                  this.commonService.setLocalStorage("isLoggedIn", true);
                  this.commonService.setLocalStorage("userDetails", userData);
                  this.readLocalStorageValue();
                  this.router.navigate(["home"]);
                  // window.location.reload()
                  this.eventEmmiterService.emitChange(this.commonService.getLocalStorage('isLoggedIn'));
                  this.bsModalRef.hide();
                  // }
                }
              }
            },
            error => {
              if (error.status == 400) {
                this.toastr.error(error.error.message, 'Error!', { timeOut: 2000 });
              } else {
                this.registerService.register(registerReq).subscribe(data => {
                  if (data.success == true) {
                    this.toastr.success(data.message, '', { timeOut: 2000 });
                    this.router.navigate(["home"]);
                    this.bsModalRef.hide();
                    // const useremail = {
                    //   emailId: facebookData.email,
                    //   loginSource: "2",
                    // };
                    //this.doLogin(useremail);
                  }
                })
                // let resendOtpEmail = {
                //   emailId: facebookData.email
                // }
                // this.registerService.resendOtp(resendOtpEmail).subscribe(data => {
                //   if (data.success == true) {
                //     this.toastr.success(data.message, '', {timeOut: 2000});
                //     this.bsModalRef.hide();
                //     this.modalRef = this.modalService.show(this.otpModal, {
                //       class: "modal-dialog-centered"
                //     });
                //     this.modalRef.hide();
                //   }
                // })
              }
            }
          );

          this.readLocalStorageValue();
        }
        // window.location.reload();
      }
    });
  }
  googlelogin() {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(data => {
      let googleData = data;
      console.log(googleData);
      const useremail = {
        emailId: googleData.email,
        loginSource: "1",
        accessToken: googleData.authToken
      };
      let registerReq = {
        emailId: googleData.email,
        firstName: googleData.firstName,
        lastName: googleData.lastName,
        accessToken: googleData.authToken,
        dateOfBirth: '',
        loginSource: "1",
        deviceTypeId: "1"
      };
      if (googleData.provider === "GOOGLE") {
        if (googleData !== undefined) {
          this.bsModalRef.hide();
          this.registerService.login(useremail).subscribe(
            data => {
              if (data.success == true) {
                let userData = data.data[0];
                if (userData) {
                  //   this.isLoading = false;
                  //   let resendOtpEmail = {
                  //     emailId: userData.emailId
                  //   }
                  //   this.registerService.resendOtp(resendOtpEmail).subscribe(data => {
                  //     if (data.success == true) {
                  //       this.toastr.success(data.data.msg, '', {timeOut: 2000});
                  //       this.bsModalRef.hide();
                  //       this.modalRef = this.modalService.show(this.otpModal, {
                  //         class: "modal-dialog-centered"
                  //       });
                  //       this.modalRef.hide();
                  //     }
                  //   })
                  this.toastr.success(data.message, '', { timeOut: 2000 });
                  this.commonService.setLocalStorage("isLoggedIn", true);
                  this.commonService.setLocalStorage("userDetails", userData);
                  this.readLocalStorageValue();
                  this.router.navigate(["home"]);
                  // window.location.reload()
                  this.eventEmmiterService.emitChange(this.commonService.getLocalStorage('isLoggedIn'));
                  this.bsModalRef.hide();
                }
              }
            },
            error => {
              if (error.status == 400) {
                this.toastr.error(error.error.message, '', { timeOut: 2000 });
              } else if (error.status == 401){
                this.registerService.register(registerReq).subscribe(data => {
                  if (data.success == true) {
                    this.toastr.success(data.message, '', { timeOut: 2000 });
                    this.router.navigate(["home"]);
                    this.bsModalRef.hide();
                    // const useremail = {
                    //   emailId: googleData.email,
                    //   loginSource: "1",
                    // };
                    //this.doLogin(useremail);
                  }
                })

                // let resendOtpEmail = {
                //   emailId: googleData.email
                // }
                // this.registerService.resendOtp(resendOtpEmail).subscribe(data => {
                //   if (data.success == true) {
                //     this.toastr.success(data.message, '', {timeOut: 2000});
                //     this.bsModalRef.hide();
                //     this.modalRef = this.modalService.show(this.otpModal, {
                //       class: "modal-dialog-centered"
                //     });
                //     this.modalRef.hide();
                //   }
                // })
              }
            }
          );
          // this.registerService.login(useremail).subscribe(data => {
          //   if(data.data[0] && data.success == true) {
          //     this.toastr.success("Login Success", "Success!");
          //     this.commonService.setLocalStorage("isLoggedIn", true);
          //     this.commonService.setLocalStorage("userDetails", data.data);
          //     this.readLocalStorageValue();
          //     this.eventEmmiterService.emitChange(
          //       this.commonService.getLocalStorage("isLoggedIn")
          //     );
          //     this.router.navigate([""]);
          //   } else {
          //     this.registerService.register(registerReq).subscribe(data => {
          //       //console.log("data",data);
          //       // this.modalRef.hide();
          //       this.toastr.success("Login Success", "Success!");
          //       this.commonService.setLocalStorage("isLoggedIn", true);
          //       this.commonService.setLocalStorage("userDetails", data);
          //       this.readLocalStorageValue();
          //       this.eventEmmiterService.emitChange(
          //         this.commonService.getLocalStorage("isLoggedIn")
          //       );
          //       this.router.navigate(["/buyticket"]);
          //     });
          //   }
          // },(error)=>{
          //   if(error.status == 401 || error.status == 402){
          //     this.registerService.register(registerReq).subscribe(data => {
          //       data.id = data.userId;
          //       // this.modalRef.hide();
          //       this.toastr.success("Login Success", "Success!");
          //       this.commonService.setLocalStorage("isLoggedIn", true);
          //       this.commonService.setLocalStorage("userDetails", data);
          //       this.readLocalStorageValue();
          //       this.eventEmmiterService.emitChange(
          //         this.commonService.getLocalStorage("isLoggedIn")
          //       );
          //       this.router.navigate(["/buyticket"]);
          //     });
          //   }
          // });
          this.readLocalStorageValue();
        }
      }
      //this.modalRef.hide();
      // window.location.reload();
    });
  }

  goToRegister() {
    this.bsModalRef.hide();
    this.router.navigateByUrl("/register");
  }
  goToForgotPwd() {
    this.bsModalRef.hide();
    this.router.navigate(["/forgotpassword"]);
  }
  onSubmitOtp(otpFrmObj) {
    this.isLoading = true;
    let verifyOtpReq = {
      emailId: this.loginObj.email,
      otp: this.otpObj.otp
    };
    const loginReq = {
      emailId: this.loginObj.email,
      password: this.loginObj.password
    };
    this.registerService.validateOtp(verifyOtpReq).subscribe(data => {
      if (data.success == true) {
        let user = data;
        if (user.data[0].userId) {
          this.modalRef.hide();
          this.registerService.login(loginReq).subscribe(data => {
            this.isLoading = false;
            this.modalRef.hide();
            this.commonService.setLocalStorage("isLoggedIn", true);
            this.commonService.setLocalStorage("userDetails", data.data[0]);
            this.readLocalStorageValue();
            this.toastr.success(data.message, '', { timeOut: 2000 });
            this.router.navigate(['']);
            this.eventEmmiterService.emitChange(this.commonService.getLocalStorage('isLoggedIn'));
            this.bsModalRef.hide()
          })
        } else {
          this.isLoading = false;
          this.toastr.error(user.data.msg, "", { timeOut: 2000 });

        }
      } else {
        this.toastr.error(data.message, "", { timeOut: 2000 });
      }
    });
  }
  onResendOTP() {
    this.resendOtpLoader = true;
    this.resendOTP = true;
    if (this.resendOTP === true) {
      let resendOtpEmail = {
        emailId: this.loginObj.email
      };
      this.registerService.resendOtp(resendOtpEmail).subscribe(data => {
        this.resendOtpLoader = false;
        if (data.success == true) {
          this.resendOTP = false;
          this.toastr.success(data.message, "", { timeOut: 2000 });
        } else {
          this.toastr.error(data.message, "", { timeOut: 2000 });
          this.resendOTP = true;
        }
      });
    } else {
      this.resendOtpLoader = false;
    }
  }

  closeAllModals() {
    this.bsModalRef.hide();
  }

}
